/*
 * The common config is a js file to make it possible to add documentation
 * to the configuration. It's highly recommended to add all possible config
 * variables here so it's also clear to anyone reading the code what could
 * affect the code. Other config files may be JSON files to reduce typing.
 */
const appName = require('../package.json').name;

const actorTypes = {
  MasterAdmin: 'masterAdmin',
  OrganizationAdmin: 'organizationAdmin',
  OrganizationOwner: 'organizationOwner',
  RegionalGeneralManager: 'regionalGeneralManager',
  DistrictManager: 'districtManager',
  LocationManager: 'locationManager',
  OrganizationCustomerSupport: 'organizationCustomerSupport',
  RegionalCustomerSupport: 'regionalCustomerSupport',
  Staff: 'staff',
  Member: 'member',
  ServiceCenter: 'serviceCenter',
  Guest: 'guest',
};

const actorRanks = {
  [actorTypes.MasterAdmin]: 1,
  [actorTypes.OrganizationOwner]: 2,
  [actorTypes.OrganizationAdmin]: 3,
  [actorTypes.RegionalGeneralManager]: 4,
  [actorTypes.DistrictManager]: 5,
  [actorTypes.LocationManager]: 6,
  [actorTypes.OrganizationCustomerSupport]: 7,
  [actorTypes.RegionalCustomerSupport]: 8,
  [actorTypes.Staff]: 9,
  [actorTypes.ServiceCenter]: 10,
  [actorTypes.Member]: 11,
  [actorTypes.Guest]: 12,
};

const viewModes = {
  Master: 'master',
  Organization: 'organization',
  Region: 'region',
  Location: 'location',
};

module.exports = {
  // The name of this app for any self-referential monitoring logic
  appName,
  // The Next server port
  port: 80,
  // The Sentry DSN (replace with a new DSN for your service)
  sentryDsn: 'https://c547a58b4e6d4a73bb65a1049da1c42d@sentry.io/1726937',
  googleMapsApiKey: 'AIzaSyBRoWdCUAnj7HtGWjsyhUYFg7diuhZbtdQ',
  // Toggle sending to sentry
  useSentry: false,
  // Sensitive information that should be omitted from logs/tickets
  sensitiveFields: ['password', 'currentPassword', 'newPassword', 'passwordHash'],
  authHost: 'https://auth.beta.stressfree.com/v1',
  graphql: {
    url: 'https://graphql.beta.stressfree.com',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'pass-mobile-version': '3.0.0',
    },
    compress: false,
  },
  routes: {
    login: {
      path: '/login',
    },
    forgotPassword: {
      path: '/forgot-password',
    },
    resetPassword: {
      path: '/reset-password',
    },
    coupons: {
      path: '/coupons',
      pageName: 'Coupons',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.LocationManager,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
      ],
      viewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
      navBarItemInViewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
    },
    appointments: {
      path: '/appointments',
      pageName: 'Appointments',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
        actorTypes.OrganizationCustomerSupport,
        actorTypes.RegionalCustomerSupport,
      ],
      viewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
      navBarItemInViewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
    },
    reports: {
      path: '/reports',
      pageName: 'Reports',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
        actorTypes.OrganizationCustomerSupport,
        actorTypes.RegionalCustomerSupport,
      ],
      viewModes: [viewModes.Region, viewModes.Location, viewModes.Organization],
      navBarItemInViewModes: [viewModes.Region, viewModes.Location, viewModes.Organization],
    },
    home: {
      path: '/',
      pageName: 'Home',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.LocationManager,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.OrganizationCustomerSupport,
        actorTypes.RegionalCustomerSupport,
      ],
      viewModes: [viewModes.Region, viewModes.Location, viewModes.Organization],
      navBarItemInViewModes: [viewModes.Region, viewModes.Location, viewModes.Organization],
    },
    team: {
      path: '/team',
      pageName: 'Team',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.LocationManager,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
      ],
      viewModes: [viewModes.Location, viewModes.Region, viewModes.Organization, viewModes.Master],
      navBarItemInViewModes: [viewModes.Location, viewModes.Region, viewModes.Organization, viewModes.Master],
    },
    user: {
      path: '/user',
      pageName: 'User',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.LocationManager,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
      ],
      viewModes: [],
      navBarItemInViewModes: [],
    },
    organizations: {
      path: '/organizations',
      pageName: 'Organizations',
      users: [actorTypes.MasterAdmin],
      viewModes: [viewModes.Master],
      navBarItemInViewModes: [viewModes.Master],
    },
    menuPricing: {
      path: '/menu-pricing',
      pageName: 'Menu pricing',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
      ],
      viewModes: [],
      navBarItemInViewModes: [],
    },
    oilChangePricing: {
      path: '/oil-change-pricing',
      pageName: 'Oil change pricing',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
      ],
      viewModes: [viewModes.Location],
      navBarItemInViewModes: [viewModes.Location],
    },
    oilType: {
      path: '/oil-type',
      pageName: 'Oil type',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
      ],
      viewModes: [],
      navBarItemInViewModes: [],
    },
    scheduling: {
      path: '/scheduling',
      pageName: 'Scheduling',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
      ],
      viewModes: [viewModes.Location, viewModes.Region, viewModes.Organization],
      navBarItemInViewModes: [viewModes.Location, viewModes.Region, viewModes.Organization],
    },
    services: {
      path: '/services',
      pageName: 'Services',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
      ],
      viewModes: [viewModes.Location, viewModes.Region, viewModes.Organization],
      navBarItemInViewModes: [viewModes.Location, viewModes.Region, viewModes.Organization],
    },
    createProfile: {
      path: '/create-profile',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
      ],
      viewModes: [viewModes.Region],
      navBarItemInViewModes: [viewModes.Region],
    },
    editProfile: {
      path: '/edit-profile',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
      ],
      viewModes: [viewModes.Organization, viewModes.Region],
      navBarItemInViewModes: [viewModes.Region],
    },
    newSettings: {
      path: '/settings/new',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
      ],
      viewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
      navBarItemInViewModes: [],
    },
    settings: {
      path: '/settings',
      pageName: 'Settings',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
      ],
      viewModes: [viewModes.Master, viewModes.Organization, viewModes.Region, viewModes.Location],
      navBarItemInViewModes: [viewModes.Master, viewModes.Organization, viewModes.Region, viewModes.Location],
    },
    newLocation: {
      path: '/settings/new-location',
      pageName: 'New Location',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
      ],
      viewModes: [viewModes.Master, viewModes.Organization, viewModes.Region],
      navBarItemInViewModes: [],
    },
    marketing: {
      path: '/marketing',
      pageName: 'Marketing',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
      ],
      viewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
      navBarItemInViewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
    },
    chat: {
      path: '/chat',
      pageName: 'Chat',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
        actorTypes.OrganizationCustomerSupport,
        actorTypes.RegionalCustomerSupport,
      ],
      viewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
      navBarItemInViewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
    },
    chatV2: {
      path: '/chatV2',
      pageName: 'Chat V2',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
        actorTypes.OrganizationCustomerSupport,
        actorTypes.RegionalCustomerSupport,
      ],
      viewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
      navBarItemInViewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
    },
  
    chatV3: {
      path: '/chatV3',
      pageName: 'Chat V3',
      users: [
        actorTypes.MasterAdmin,
        actorTypes.OrganizationAdmin,
        actorTypes.OrganizationOwner,
        actorTypes.RegionalGeneralManager,
        actorTypes.DistrictManager,
        actorTypes.LocationManager,
        actorTypes.OrganizationCustomerSupport,
        actorTypes.RegionalCustomerSupport,
      ],
      viewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
      navBarItemInViewModes: [viewModes.Organization, viewModes.Region, viewModes.Location],
    },
    yinonOnly: {
      path: '/yinon-only',
      pageName: 'Yinon only',
      users: [actorTypes.MasterAdmin, actorTypes.OrganizationAdmin, actorTypes.OrganizationOwner],
      viewModes: [viewModes.Organization],
      navBarItemInViewModes: [],
    },
    notFound: {
      path: '/not-found',
      users: [],
      viewModes: [],
      navBarItemInViewModes: [],
    },
  },
  whitelistedPaths: ['/calendar-pig', '/calendar-horse', '/calendar-rooster'],
  actorTypes,
  actorRanks,
  viewModes,
  // Enable SDC (off by default)
  useSdc: false,
  // The Dogstatsd configuration
  dogstatsd: {
    host: '172.17.0.1',
    port: 8125,
    socket: undefined,
    options: {
      global_tags: [`app:${appName}`, `env:${process.env.CARDASH_ENV}`],
    },
  },
  // ga analytics enabled on prod
  analyticsEnabled: false,
  sfacOrganizationId: 1,
};
