import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import config from '@cardash/config';
import { useQuery } from '@apollo/client';

import { Spinner } from 'reactstrap';
import Error from '../../Error';
import { StyledSelect } from './StyledComponents';
import { GetRegionsQuery } from '../../graphql';
import useMainContext from '../../../hooks/main-provider';
import { actorRanks } from '../../../../config/common';
import { useSession } from 'next-auth/react';

const GetRegions = gql`
  ${GetRegionsQuery}
`;
const RegionField = () => {
  const { data: session } = useSession();

  const user = session?.user;

  const { updateSelectedRegion, selectedOrganization, selectedRegion, viewModeDataLoading } = useMainContext();

  const { data, loading, error } = useQuery(GetRegions, {
    variables: { id: selectedOrganization ? selectedOrganization.id : null },
    fetchPolicy: 'network-only',
  });

  const regions = useMemo(() => (data && data.regions) || [], [data]);

  const options = useMemo(
    () =>
      (regions || []).map(r => ({
        label: `${r.name} ${r.displayName ? `- ${r.displayName}` : ''}`,
        value: r.id,
      })),
    [regions]
  );

  const selectedValue = useMemo(() => {
    const region = selectedRegion && regions.find(r => r.id === Number(selectedRegion.id));
    if (region) {
      return {
        label: `${region.name} ${region.displayName ? `- ${region.displayName}` : ''}`,
        value: region.id,
      };
    }
    return null;
  }, [selectedRegion, regions]);

  if (error) return <Error error={error} />;

  if (user.type === config.actorTypes.LocationManager) {
    if (loading) {
      return (
        <div>
          <Spinner size="sm" />
        </div>
      );
    }
    if (regions?.length === 1) {
      return <p className="text-light mb-0 me-2 ml-1">{regions[0]?.name || '-'}</p>;
    }
  }

  const isClearable = user.rank < actorRanks.regionalGeneralManager || user.rank === actorRanks.regionalCustomerSupport;

  return (
    <StyledSelect
      classNamePrefix="side-menu-select"
      isClearable={isClearable}
      onChange={selectedItem => {
        const region = selectedItem && regions.find(r => r.id === Number(selectedItem.value));
        updateSelectedRegion(region && region.id);
      }}
      isLoading={loading || viewModeDataLoading}
      value={selectedValue}
      options={options}
    />
  );
};

const Region = () => {
  return (
    <div className="d-flex w-100 mb-2" style={{ maxWidth: 250, margin: '10px 15px' }}>
      <p className="text-light mb-0 me-2">Region:</p>
      <RegionField />
    </div>
  );
};

export default Region;
