import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import Error from '../../Error';
import { GetSFACLocationsQuery } from '../../graphql';
import useMainContext from '../../../hooks/main-provider';
import { StyledSelect } from './StyledComponents';
import { actorRanks } from '../../../../config/common';
import useOrganizationName from '../../../hooks/useOrganizationName';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

const GetSFACLocations = gql`
  ${GetSFACLocationsQuery}
`;

const ShopsSelectField = () => {
  const { push, query } = useRouter();

  const { data: session } = useSession();

  const user = session?.user;

  const { selectedRegion, updateSelectedShop, selectedShop, viewModeDataLoading } = useMainContext();

  const organizationName = useOrganizationName();

  const {
    data = { getSFACLocations: [] },
    loading,
    error,
  } = useQuery(GetSFACLocations, {
    variables: {
      showInactive: true,
      regionId: selectedRegion && selectedRegion.id,
    },
  });

  const selectedValue = useMemo(
    () =>
      selectedShop && {
        label: `${organizationName} ${selectedShop.locationIndex} - ${selectedShop.displayLocation}`,
        value: selectedShop.id,
      },
    [organizationName, selectedShop]
  );

  const isClearable = user.rank < actorRanks.locationManager || user.rank === actorRanks.regionalCustomerSupport;

  if (error) return <Error error={error} />;
  return (
    <div className="d-flex w-100 mb-2" style={{ maxWidth: 250, margin: '10px 15px' }}>
      <p className="text-light mb-0 me-2 ml-1">Shop:</p>
      <StyledSelect
        classNamePrefix="side-menu-select"
        onChange={selectedItem => {
          const location = selectedItem && data.getSFACLocations.find(l => l.id === Number(selectedItem.value));
          if (location) {
            push({ query: { ...query, locationId: location.id } }, undefined, {
              shallow: true,
            });
          }
          updateSelectedShop(location && location.id);
        }}
        isClearable={isClearable}
        options={
          selectedRegion
            ? data.getSFACLocations.map(l => ({
                label: `${organizationName} ${l.locationIndex} - ${l.displayLocation}`,
                value: l.id,
              }))
            : []
        }
        value={selectedValue}
        loading={loading || viewModeDataLoading}
      />
    </div>
  );
};

export default ShopsSelectField;
